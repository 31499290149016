/* Estilos Generales */
body {
  font-family: 'Roboto', sans-serif !important;
  background-color: #f8f9fa !important; /* Color de fondo claro */
}

h1, h2 {
  color: #333 !important; /* Título oscuro */
  font-weight: bold !important;
}

p {
  color: #555 !important; /* Texto ligeramente más claro */
  font-size: 24px !important;
  line-height: 1.6 !important;
  margin-left: 10%;
  margin-right: 10%;
}

/* Sección "Acerca de Nosotros" */
#nosotros {
  color: #333 !important; /* Color oscuro para el texto */
}

#nosotros h2 {
  color: #009de3 !important; /* Azul Bootstrap */
  font-weight: bold !important;
  margin-bottom: 20px !important;
}

#nosotros p {
  color: #333 !important; /* Color gris oscuro */
}

/* Navbar */
.navbar {
  background-color: #ffffff !important; /* Fondo blanco */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important; /* Sombra ligera */
}

.navbar-brand {
  font-size: 24px !important;
  font-weight: bold !important;
}

.navbar-nav .nav-link {
  color: #009de3 !important;
  font-weight: 500 !important;
}

.navbar-nav .nav-link:hover {
  color: #0056b3 !important;
}

/* Secciones */
section {
  background-color: #fff !important;
  padding: 40px !important;
  margin-bottom: 20px !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05) !important;
  text-align: center !important;
}

section h2 {
  font-size: 52px !important;
  margin-bottom: 20px !important;
}

section p {
  margin-bottom: 15px !important;
}

/* Ubicación y Horarios */
.row .col-lg-6 {
  padding: 20px !important;
  text-align: center !important; /* Centrar las columnas */
}

@media (max-width: 768px) {

  .row .col-lg-6 {
    padding: 10px 0 !important;    
  }
  .banner-container {
    max-height: 300px; /* Ajusta la altura en móviles */
  }

  .banner-container img {
    object-fit: contain; /* Asegura que la imagen se vea completa */
    max-height: 300px;
  }

  .cel h2{
    font-size: 24px !important;
  }

  .cel p{
    
    font-size: 16px !important;
  }

  .cel h3{
    
    font-size: 16px !important;
  }

}


.mission-vision-header {
  display: flex;
  align-items: center; /* Centra verticalmente el ícono y el texto */
  justify-content: center; /* Centra horizontalmente el contenedor */
  text-align: center;
  margin-bottom: 1rem; /* Espacio entre el encabezado y el párrafo */
}

.icon {
  font-size: 32px; /* Ajusta el tamaño del ícono según lo necesites */
  color: #009de3; /* Cambia el color del ícono */
}

.mission-vision-item h2 {
  margin-bottom: 0.5rem; /* Espacio entre el encabezado y el párrafo */
}


.contact-section {
  background-color: #f1f1f1; /* Fondo gris claro */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
}

.contact-section h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.text-primary {
  color: #009de3 !important; /* Color azul de los encabezados */
}

.fw-bold {
  font-weight: bold;
}

.map-placeholder {
  width: 100%;
  height: 300px;
  background-color: #ddd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 18px;
  font-style: italic;
}

/* Asegurarse de que el texto esté alineado a la izquierda */
.text-start {
  text-align: left !important;
}


.footer-section {
  background-color: #009de3 !important; /* Fondo azul */
  color: white !important;
  padding: 20px 0 !important;
}

.footer-section p {
  margin: 0.5rem 0 !important; /* Espaciado entre líneas */
  color: white !important; /* Texto blanco */
}

.footer-section h5 {
  margin-top: 1rem !important;
  color: white !important; /* Encabezado en blanco */
}

.footer-section a {
  color: white !important;
  text-decoration: underline !important;
}

.footer-section form .form-control {
  border-radius: 5px !important;
  border: none !important;
  height: 40px !important;
}

.footer-section .btn-dark {
  border-radius: 5px !important;
  height: 40px !important;
  min-width: 100px !important;
}

.footer-section .bi {
  font-size: 1.5rem !important; /* Tamaño de los íconos */
  color: white !important; /* Íconos en blanco */
}

.text-center {
  text-align: center !important;
}

.logo-img {
  height: 50px; /* Ajusta la altura del logo */
  width: auto; /* Mantén la proporción de la imagen */
}

.redes-img {
  height: 20px; /* Ajusta la altura del logo */
  width: auto; /* Mantén la proporción de la imagen */
  margin-left: 10px;
}

.banner-container {
  width: 100%;
  height: auto; /* Permitir que la altura se ajuste automáticamente */
  max-height: 500px; /* Limita la altura en pantallas grandes */
  overflow: hidden;
  position: relative;
}

.banner-container img {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: cover; /* Asegura que la imagen llene el contenedor sin distorsionarse */
  object-position: center; /* Centra la imagen dentro del contenedor */
}

.mission-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.vision-img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}


.mission-vision-section {
  background-image: url('./assets/Fondo azul patlan.png') !important; /* Ajusta la ruta si es necesario */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 400px;
  padding: 50px 0;
  color: white;
  border-radius: 10px;
}

